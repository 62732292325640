<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('prep_attendance')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('prep_attendance')"
                              :isFilter="false"/>
            </template>
            <div class="d-flex flex-column flex-lg-row full-height overflow-hidden">
                <div class="width-300 mr-3 d-none d-lg-block" style="min-width: 300px;">
                    <div class="sections d-flex flex-column">
                        <h5>{{ $t('sections') }}</h5>
                        <div class="filter">
                            <b-form-input v-model="sectionFilter"
                                          size="lg"
                                          :placeholder="$t('filter')"
                            />
                        </div>
                        <div class="flex-grow-1 section-list" v-if="sectionLoading">
                            <loading :show="sectionLoading"/>
                        </div>
                        <div class="flex-grow-1 section-list d-none d-lg-block" id="section-list" v-else>
                            <div class="text-center" v-if="filteredSections.length==0">
                                {{ $t('no_result_2') }}
                            </div>
                            <div class="section cursor-pointer"
                                 :class="section_id && section_id === item.section_id ?  'selected-item':''"
                                 :id="'section-'+item.section_id"
                                 v-for="(item, key) in filteredSections"
                                 :key="'section-'+item.section_id"
                                 :ref="'section-'+item.section_id"
                                 @click="section_id = item.section_id"
                                 v-else
                            >
                                <div class="name">{{ getLocaleText(item, 'course_name') }}</div>
                                <div class="other">
                                    <b-badge variant="dark">{{ item.course_code }}</b-badge>
                                    <b-badge variant="dark" v-if="item.section">Section: {{ item.section }}</b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="width-300 mr-3 d-none d-lg-block" style="min-width: 300px;">
                    <div class="weeks d-flex flex-column">
                        <h5>{{ $t('week') }} / {{ $t('date') }}</h5>
                        <div class="flex-grow-1 week-list" v-if="weekLoading">
                            <loading :show="weekLoading"/>
                        </div>
                        <div class="flex-grow-1 week-list" v-else>
                            <div class="text-center" v-if="weeks.length==0">
                                {{ $t('no_result_2') }}
                            </div>
                            <div class="week cursor-pointer"
                                 :class="week && week == item.week ? 'selected-item':''"
                                 v-for="(item, key) in weeks"
                                 :key="key"
                                 @click="selectWeek(item.week)"
                                 v-else
                            >
                                <div class="d-flex">
                                    <div class="flex-grow-1 d-flex flex-column align-items-center">
                                        <span>{{ item.week }}</span>
                                        <span>{{ $t('week') }}</span>
                                    </div>
                                    <div class="flex-grow-1 d-flex flex-column align-items-center">
                                        <span>{{ toLocaleDate(item.date) }}</span>
                                        <span>{{ $t('date') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="h-100 d-flex flex-column">
                        <div class="mb-3 d-block d-lg-none">
                            <h5>{{ $t('sections') }}</h5>
                            <b-form-select :options="sectionsOptions"
                                           v-model="section_id"
                                           @input="selectSection(section_id)"
                            />
                        </div>
                        <div class="mb-3 d-block d-lg-none">
                            <h5>{{ $t('week') + ' / ' + $t('date') }}</h5>
                            <b-form-select :options="weeksOptions"
                                           v-model="week"
                                           @input="selectSection(section_id)"
                            />
                        </div>
                        <h5>{{ $t('students') }}</h5>
                        <div class="students d-flex flex-column">
                            <div class="flex-grow-1 week-list" v-if="studentLoading">
                                <loading :show="studentLoading"/>
                            </div>
                            <div v-else>
                                <div class="text-center" v-if="students.length==0">
                                    {{ $t('no_result_2') }}
                                </div>
                                <div v-else>
                                    <b-table
                                        :empty-filtered-text="this.$t('table_not_found')"
                                        :empty-text="this.$t('table_is_empty')"
                                        bordered
                                        striped
                                        responsive
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :items="sortedStudents"
                                        :fields="studentsField"
                                        @sort-changed="onSortChanged"
                                        class="mb-0 table-dropdown no-scrollbar border rounded"
                                    >

                                        <template #head(hours)>
                                            <span >{{ $t('mass_absence_chose') }}</span>
                                                <b-form-group v-slot="{ ariaDescribedby }" >
                                                    <div class="d-flex">
                                                        <b-form-radio-group
                                                            :aria-describedby="ariaDescribedby"
                                                            v-model="massPollingSelection"
                                                            :options="hours"
                                                           >
                                                        </b-form-radio-group>
                                                    </div>
                                                </b-form-group>
                                            </template>
                                        <template #cell(student)="row">
                                            <span class="d-block d-lg-none">{{ row.item.student_number }}</span>
                                            <span>{{ row.item.name }} {{ row.item.surname }}<br></span>
                                            <span>{{ getLocaleText(row.item, 'class').toUpper() }}<br></span>
                                            <span>{{ getLocaleText(row.item, 'program_name') }}<br></span>
                                        </template>

                                        <template #cell(hours)="row">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <b-form-radio-group
                                                    :key="'rb_'+row.item.student_number"
                                                     v-model="sortedStudents[row.index].hour"
                                                    :options="hours"
                                                    :aria-describedby="ariaDescribedby"
                                                    :name="'rb_'+row.item.student_number"
                                                ></b-form-radio-group>
                                            </b-form-group>
                                        </template>

                                    </b-table>
                                    <div class="d-flex justify-content-center mt-4">
                                        <b-button variant="primary"
                                                  @click="save()"
                                                  v-if="this.checkPermission('attendance_storeattendance')">
                                            {{ $t('save') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

    // Component
    import Loading from '@/components/elements/Loading2'

    // Services
    import AttendanceService from '@/services/AttendanceService'
    import AttendanceEntryService from '@/services/AttendanceEntryService'
    import students from "@/modules/sections/pages/instructor/Students.vue";

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            Loading
        },
        metaInfo() {
            return {
                title: this.$t('prep_attendance')
            }
        },

        computed: {
            sortedStudents() {
                const studentsCopy = [...this.students];
                if (this.sortBy) {
                    studentsCopy.sort((a, b) => {
                        const fieldA = a[this.sortBy];
                        const fieldB = b[this.sortBy];
                        if (this.sortDesc) {
                            return fieldB.localeCompare(fieldA);
                        } else {
                            return fieldA.localeCompare(fieldB);
                        }
                    });
                }
                return studentsCopy;
            },

            filteredSections() {
                if (this.sectionFilter) {
                    return this.sections.filter(item => {
                        let courseCodeValue = item.course_code.toUpperCase().replaceAll(/[İi]/ig, 'I');
                        let courseNameValue = item.course_name.toUpperCase().replaceAll(/[İi]/ig, 'I');
                        let courseNameEnValue = item.course_name_en.toUpperCase().replaceAll(/[İi]/ig, 'I');

                        let filterValue = this.sectionFilter.toUpperCase().replaceAll(/[İi]/ig, 'I');

                        return courseCodeValue.indexOf(filterValue) > -1 || courseNameValue.indexOf(filterValue) > -1 || courseNameEnValue.indexOf(filterValue) > -1
                    })
                }
                else {
                    return this.sections;
                }
            }
        },
        data() {
            return {
                sortBy: null,
                sortDesc: false,
                massPollingSelection:false,
                // Sections
                sectionLoading: false,
                sectionFilter: null,
                sections: [],
                sectionsOptions: [],
                section: {},
                section_id: null,

                // Weeks
                weekLoading: false,
                weeksOptions: [],
                weeks: [],
                week: null,

                // Students
                studentLoading: false,
                students: [],
                studentsField: [

                    {
                        key: 'student_number',
                        label: this.$t('student_number').toUpper(),
                        class: 'd-none d-lg-block',
                        sortable: true
                    },
                    {
                        key: 'student',
                        label: this.$t('student').toUpper(),
                        sortable: true
                    },
                    {
                        key: 'hours',
                        label: this.$t('hours').toUpper(),
                        sortable: false
                    },
                ],

                // Hours
                hours: []
            }
        },
        watch: {
            section_id: function(newValue) {
                this.selectSection(newValue)
                this.getWeeks();
            },
            week: function(newValue) {
                this.getStudents();
            },
            massPollingSelection:function (newValue){
                this.students.map(itm=>{
                    itm.hour=newValue
                })
            }
        },
        created() {
            this.getSections().then(() => {
                if (this.$route.params.sectionId) {
                    let section = this.$refs['section-' + this.$route.params.sectionId];
                    section[0].click();

                    let sectionListTop = document.getElementsByClassName('section-list')[0].offsetTop
                    let sectionTop = document.getElementById('section-' + this.$route.params.sectionId).offsetTop
                    document.getElementsByClassName('section-list')[0].scrollTop = sectionTop - (sectionListTop+20);
                }
            })
        },
        methods: {
            onSortChanged({ sortBy, sortDesc }) {
                this.sortBy = sortBy;
                this.sortDesc = sortDesc;
            },
            // Sections
            async getSections() {
                this.sectionLoading = true
                await AttendanceEntryService.getAll()
                                            .then((response) => {
                                                let data = response.data.data;
                                                this.sections = data;
                                                this.sectionsOptions = [];
                                                data.forEach((item) => {
                                                    this.sectionsOptions.push({
                                                        value: item.section_id,
                                                        text: item.course_code + ' - ' + this.getLocaleText(item, 'course_name') + ' - Section: ' + item.section
                                                    })
                                                })
                                            })
                                            .catch((e) => {
                                                this.showErrors(e)
                                            })
                                            .finally(() => {
                                                this.sectionLoading = false
                                            })
            },
            selectSection(section_id) {
                this.section_id = section_id
                this.section = this.sections.find(item => item.section_id == section_id)
                this.hours = [{value:0, text: this.$t('attended')}];
                if (this.section) {
                    let total = parseInt(this.section.theoretical_credit) + parseInt(this.section.practical_credit) + parseInt(this.section.lab_credit);
                    if(this.section.semester == 3){
                        total = 2 * total
                    }
                    this.hours.push({value: total, text: this.$t('not_attended')});
                    /*for (let i = 0; i <= total; i++) {
                        this.hours.push({value: i, text: i});
                    }*/
                }
            },
            // Weeks
            getWeeks() {
                this.weeks = [];
                this.week = null;
                this.students = [];
                if (this.section_id) {
                    this.weekLoading = true;
                    AttendanceService.getAttendanceForSection(this.section_id)
                                     .then((response) => {
                                         let data = response.data.data
                                         this.weeks = data
                                         data.forEach((item) => {
                                             this.weeksOptions.push({
                                                 value: item.week,
                                                 text: item.week + ' .' + this.$t('week') + ' - ' + this.toLocaleDate(item.date)
                                             })
                                         })
                                     })
                                     .catch((e) => {
                                         this.showErrors(e)
                                     })
                                     .finally(() => {
                                         this.weekLoading = false
                                     })
                }
            },
            selectWeek(week) {
                this.week = week;
            },
            // Students
            getStudents() {
                this.students = [];
                if (this.section_id && this.week) {

                    this.studentLoading = true;
                    AttendanceService.getSectionStudents(this.section_id)
                                     .then((response) => {
                                         let data = response.data.data;
                                         this.students = data.map(item =>
                                             (
                                                 {
                                                     ...item,
                                                     hour: 0
                                                 }
                                             )
                                         )
                                     })
                                     .then(() => {
                                         AttendanceService.getAttendanceWithSectionAndWeek(this.section_id, this.week)
                                                          .then((response) => {
                                                              let data = response.data.data;
                                                              Object.keys(data)
                                                                    .map((key) => {
                                                                        let index = this.students.findIndex(item => item.student_program_id == key)
                                                                        if (index > -1) {
                                                                            this.students[index].hour = data[key][0].hours
                                                                        }
                                                                    })
                                                          })
                                                          .catch((e) => {
                                                              this.showErrors(e);
                                                          })
                                     })
                                     .catch((e) => {
                                         this.showErrors(e);
                                     })
                                     .finally(() => {
                                         this.studentLoading = false
                                     })
                }
            },

            // Save
            save() {
                let attendances = [];
                this.students.forEach((item) => {
                    attendances.push({
                        student_program_id: item.student_program_id,
                        hours: item.hour
                    })
                })

                let data = {
                    section_id: this.section_id,
                    week: this.week,
                    attendances: attendances
                }
                AttendanceService.save(data)
                                 .then((res) => {
                                     this.$toast.success(this.$t('successfully_updated'));
                                 })
                                 .catch((e) => {
                                     this.showErrors(e);
                                 })
            }
        }
    }
</script>

<style scoped>

    /* Sections */
    .sections {
        height: 100%;
    }

    .sections .filter {
        margin-bottom: 5px;
    }

    .sections .filter input {
        border-radius: 5px;
    }

    .sections .section-list {
        border: 1px solid #e9ecef;
        background: #f9f9f9;
        padding: 18px 15px;
        border-radius: 4px;
        overflow-y: auto;
    }

    .sections .section-list .section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100px;
        border: 1px solid #e9ecef;
        padding: 15px 15px 10px 15px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #fff;
    }

    .selected-item {
        background: #00aeef !important;
        color: #fff !important;
    }

    .sections .section-list .section .name {
        font-size: 15px;
        line-height: 16px;
    }

    .sections .section-list .section .other {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
    }

    .sections .section-list .section .other .badge {
        border: 1px solid #e9ecef;
        background: #f1f1f1;
        padding: 4px 6px;
        border-radius: 2px;
        font-weight: 500;
    }

    /*  Weeks */
    .weeks {
        height: 100%;
    }

    .weeks .week-list {
        border: 1px solid #e9ecef;
        background: #f9f9f9;
        padding: 18px 15px;
        border-radius: 4px;
        overflow-y: auto;
    }

    .weeks .week-list .week {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #e9ecef;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #fff;
    }

    .weeks .week-list .week span:first-child {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .weeks .week-list .week span:last-child {
        font-size: 14px;
    }

    /* Students */
    .students {
        height: 100%;
        border: 1px solid #e9ecef;
        border-radius: 4px;
        padding: 18px 15px;
        overflow-y: auto;
    }

</style>
